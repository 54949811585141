import { UniSupportApiCS } from '@unikey/unikey-commons/release/csupp'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'uni';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://uni-stg-dealer-api.unikey.com/v2/nonces' };
export const supportApi: UniSupportApiCS = new UniSupportApiCS('https://uni-stg-support-api.unikey.com', 'api/v1', 'UNI', { noNonce: true, enableLogging: true, ...commNonceEndpoint });
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', '9f6b1fab-db5f-4e05-9d1e-02a43480ee72', 'l74060zdU4GHFKACHITieKEOd5vOgHyQsfqd2dM0adKXiTdS1FId9An6biVzq6y3ysr0WisBfHf7fSxoQW3T9Q==', { enableLogging: false, ...commNonceEndpoint });

export const oidcScope: string = 'openid email profile comm.support.api identity.api offline_access';
export const oidcClientId: string = '9f6b1fab-db5f-4e05-9d1e-02a43480ee72';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://uni-stg-support.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = 'l74060zdU4GHFKACHITieKEOd5vOgHyQsfqd2dM0adKXiTdS1FId9An6biVzq6y3ysr0WisBfHf7fSxoQW3T9Q==';
export const oidcPartnerBrandId: string = '87ccc2dd-6a00-49cb-bdba-255a26dae171';
export const oidcPartnerBrandCode: string = 'UNI';
export const oidcSilentRedirectUri: string = 'https://uni-stg-support.unikey.com/#/renew';
export const oidcForgotPasswordRedirect: string = '';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://www.unikey.com/lets-connect/';

export const appInsightsInstrumentationKey: string = '7e59cc18-0926-40e7-83e0-e1ccb2ddf44d';
export const mockAuth: string = '';

export const buildId: string = '36429';
export const releaseId: string = '6208';
export const cspVersion: string = '3.10.0';
export const commonsVersion: string = '8.0.0-pre-1226';

export const desiredLocales: string = 'en,es,ja,fr,pt';
